// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAEVzifvbg1i8NNTXkkGNLlsd1bVDV9_yU",
  authDomain: "yvom1st.firebaseapp.com",
  databaseURL: "https://yvom1st-default-rtdb.firebaseio.com",
  projectId: "yvom1st",
  storageBucket: "yvom1st.appspot.com",
  messagingSenderId: "693087122868",
  appId: "1:693087122868:web:5e80508d50b92279136ee1",
  measurementId: "G-1V1BVX0Y45"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };